var __CLICKCHAT__URL = __CLICKCHAT__getWidgetURL();

function __CLICKCHAT__ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
}

const widgetStyle = `
.clickchat-widget {
  position: fixed;
  z-index: 9999999;
  right: 20px;
  bottom: 20px;
  min-height: 100px;
  min-width: 80px;
  height:300px;  
  width:300px;
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.clickchat-widget.wb-align-left {
  left: 0;
  right: auto;
}
.clickchat-widget.wb-expand {
  width: 416px;
  height: 610px;
  min-width: 370px;
}
.clickchat-widget.wb-maximize {
  width: 50%;
  height: calc(100% - 30px)
}
@media only screen and (device-width: 768px),
       only screen and (max-width: 768px){
  .clickchat-widget.wb-maximize {
    width: 100%;
    height: 100%;
  }
  .clickchat-widget {
    right: 0;
    bottom: 0;
  }
  .clickchat-widget.wb-expand {
    width: 100%;
    height: 100%;
    min-width: 0;
    left: 0;
    right: 10px;
    bottom: 10px;
    top: 0;
    max-width: 100%;
    max-height: 100%;
  }
}
`;

function __CLICKCHAT__addIframe() {
  var ifrm = document.createElement('iframe');
  const kbId = __CLICKCHAT__getChatbotId();
  ifrm.setAttribute('class', 'clickchat-widget');
  ifrm.setAttribute('id', 'clickchat-widget');
  ifrm.setAttribute('data-powered-by', 'https://mets.vip/');
  ifrm.setAttribute('frameborder', '0');

  const container = document
    .getElementById('__chatbotSdk__')
    .getAttribute('container');

  if (container) {
    const $container = document.querySelector(container);
    if ($container) {
      $container.appendChild(ifrm);
    }
  } else {
    var styleSheet = document.createElement('style');
    styleSheet.innerText = widgetStyle;
    document.head.appendChild(styleSheet);
    document.body.appendChild(ifrm);
  }

  const baseUrl = __CLICKCHAT__getBaseURL();
  ifrm.setAttribute(
    'src',
    __CLICKCHAT__URL + '?kbId=' + kbId + '&baseUrl=' + baseUrl,
  );
  return ifrm;
}

function __CLICKCHAT__getEventHandler(ifrm) {
  const iframe = document.getElementById('clickchat-widget');
  function clickChatEventHandler(e) {
    if (e.data === 'clickchat:widget_expand') {
      iframe.classList.add('wb-expand');
    } else if (e.data === 'clickchat:widget_collapse') {
      setTimeout(() => {
        iframe.classList.remove('wb-expand', 'wb-maximize');
      }, 350);
    } else if (e.data === 'clickchat:widget_maximize') {
      iframe.classList.add('wb-maximize');
    } else if (e.data === 'clickchat:widget_minimize') {
      iframe.classList.remove('wb-maximize');
    } else if (e.data === 'clickchat:widget_align_left') {
      iframe.classList.add('wb-align-left');
    } else if (e.data === 'clickchat:widget_clear_history') {
      localStorage.removeItem('chatHistory');
      localStorage.removeItem('sessionId');
    } else if (e.data === 'clickchat:request_meta_data') {
      const container = document
        .getElementById('__chatbotSdk__')
        .getAttribute('container');
      e.source.postMessage(
        {
          messageType: 'clickchat:recieve_meta_data',
          url: window.location.href,
          container: container,
        },
        '*',
      );
    } else if (e.data === 'clickchat:request_chat_data') {
      const chatHistoryData = localStorage.getItem('chatHistory');
      const chatHistory = JSON.parse(chatHistoryData || '[]');
      e.source.postMessage(
        {
          messageType: 'clickchat:recieve_chat_data',
          chatHistory: chatHistory,
        },
        '*',
      );
    } else if (e.data === 'clickchat:request_session_id') {
      const sessionId = localStorage.getItem('sessionId');
      e.source.postMessage(
        { messageType: 'clickchat:recieve_session_id', sessionId: sessionId },
        '*',
      );
    } else if (
      e.data &&
      e.data.messageType === 'clickchat:recieve_new_message'
    ) {
      const chatHistoryData = localStorage.getItem('chatHistory');
      let chatHistory = JSON.parse(chatHistoryData || '[]');
      const msg = e.data.message || [];
      chatHistory = [...chatHistory, ...msg];
      localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
    } else if (
      e.data &&
      e.data.messageType === 'clickchat:recieve_new_session_id'
    ) {
      localStorage.setItem('sessionId', e.data.sessionId);
    }
  }

  return clickChatEventHandler;
}

function __CLICKCHAT__addEventListeners(ifrm) {
  window.onmessage = __CLICKCHAT__getEventHandler(ifrm);
}

function __CLICKCHAT__getChatbotId() {
  const chatbotId = document
    .getElementById('__chatbotSdk__')
    .getAttribute('chatbotId');
  return chatbotId;
}

function __CLICKCHAT__getWidgetURL() {
  const baseURL = document
    .getElementById('__chatbotSdk__')
    .getAttribute('widgetUrl');
  return baseURL || 'https://mets.vip/';
}

function __CLICKCHAT__getBaseURL() {
  const baseURL = document
    .getElementById('__chatbotSdk__')
    .getAttribute('baseUrl');
  return baseURL || 'https://mets.vip/api';
}

__CLICKCHAT__ready(() => {
  setTimeout(() => {
    var ifrm = __CLICKCHAT__addIframe();
    __CLICKCHAT__addEventListeners(ifrm);
  }, 1000);
});
